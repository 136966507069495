import React, { createContext, useContext, useReducer } from "react";
import { initialState, rootReducer } from "../reducers";

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return (
    <StateContext.Provider value={[state, dispatch]}>
      {children}
    </StateContext.Provider>
  );
};

export const withUser = (Component) => (props) => (
  <StateContext.Consumer>
    {([{ user }]) => <Component {...props} user={user} />}
  </StateContext.Consumer>
);

export const useStateContext = () => useContext(StateContext);
