import { USERS_FETCH, USERS_UPDATE, USERS_DELETE } from "../actions/users";

export const usersReducer = (state, action) => {
  const payload = action.payload;

  switch (action.type) {
    case USERS_FETCH:
      return [...payload];
    case USERS_UPDATE:
      const updatedUsers = state.map((user) => {
        return user.uid === payload.uid ? payload : user;
      });
      return [...updatedUsers];
    case USERS_DELETE:
      const userIndex = state.findIndex((user) => user.id === payload);
      state.splice(userIndex, 1);
      return [...state];
    default:
      return [...state];
  }
};

export const usersInitialState = [];
