import {
  Checkbox,
  Container,
  FormControlLabel,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import SyncIcon from "@material-ui/icons/Sync";

import PersonSelect from "../components/personSelect";

import { useFirebase } from "../contexts/firebase";
import { useStateContext } from "../contexts/state";

import { USERS_FETCH, USERS_UPDATE, USERS_DELETE } from "../actions/users";
import { FAMILY_UPDATE } from "../actions/family";

const UserRow = withStyles({
  root: {
    backgroundColor: "#ffffff",
  },
})(TableRow);

export default function Users() {
  const {
    addAdmin,
    authorizeUser,
    deleteAuthUser,
    getAuthUsers,
    removeAdmin,
    unauthorizeUser,
    updateFamilyMember,
  } = useFirebase();
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const [{ users }, dispatch] = useStateContext();
  const [currentUser, setCurrentUser] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const onChangeAdmin = (user) => {
    if (user.customClaims && user.customClaims.admin) {
      return onRemoveAdmin(user);
    } else {
      return onAddAdmin(user);
    }
  };

  const onChangeAuthorize = (user) => {
    if (!user.customClaims || !user.customClaims.read) {
      return onAuthorize(user);
    }
    return onUnauthorize(user);
  };

  const onAddAdmin = (user) => {
    return async () => {
      try {
        await addAdmin(user.uid);
        dispatch({
          type: USERS_UPDATE,
          payload: {
            ...user,
            customClaims: {
              ...user.customClaims,
              admin: true,
              read: true,
            },
          },
        });
        enqueueSnackbar("Admin added successfully", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Error adding admin", { variant: "error" });
      }
    };
  };

  const onRemoveAdmin = (user) => {
    return async () => {
      try {
        await removeAdmin(user.uid, user.customClaims.read);
        dispatch({
          type: USERS_UPDATE,
          payload: {
            ...user,
            customClaims: {
              ...user.customClaims,
              admin: false,
            },
          },
        });
        enqueueSnackbar("Admin removed successfully", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Error removing admin", { variant: "error" });
      }
    };
  };

  const onAuthorize = (user) => {
    return async () => {
      await authorizeUser(user.uid);
      dispatch({
        type: USERS_UPDATE,
        payload: {
          ...user,
          customClaims: {
            read: true,
          },
        },
      });
      try {
        enqueueSnackbar("User successfully authorized", { variant: "success" });
      } catch {
        enqueueSnackbar("Error authorizing user", { variant: "error" });
      }
    };
  };

  const onUnauthorize = (user) => {
    return async () => {
      try {
        await unauthorizeUser(user.uid);
        dispatch({
          type: USERS_UPDATE,
          payload: {
            ...user,
            customClaims: {
              read: false,
            },
          },
        });
        enqueueSnackbar("User successfully unauthorized", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("Error unauthorizing user", { variant: "error" });
      }
    };
  };

  const onDelete = (user) => {
    return async () => {
      await deleteAuthUser(user.uid);
      dispatch({ type: USERS_DELETE, payload: user });
      try {
        enqueueSnackbar("User successfully deleted", { variant: "success" });
      } catch {
        enqueueSnackbar("Error removing user", { variant: "error" });
      }
    };
  };

  const onOpenSelect = (user) => {
    return () => {
      setCurrentUser(user);
      setIsUserSelectOpen(true);
    };
  };

  const onCloseSelect = () => {
    setCurrentUser(null);
    setIsUserSelectOpen(false);
  };

  const onSync = async (selected) => {
    setIsUserSelectOpen(false);

    try {
      const updates = {
        ...selected,
        uid: currentUser.uid
      };

      await updateFamilyMember(updates);
      dispatch({
        type: FAMILY_UPDATE,
        payload: updates,
      });
      enqueueSnackbar("User successfully synced", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Error syncing user: ${error}`, { variant: "error" });
    }

    setCurrentUser(null);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await getAuthUsers();

        if (res.data.users) {
          dispatch({ type: USERS_FETCH, payload: [...res.data.users] });
        }
      } catch (error) {
        enqueueSnackbar("Error retrieving users", { variant: "error" });
      }
    };

    if (users.length) {
      return;
    }

    fetchUsers();
  }, [dispatch, getAuthUsers, enqueueSnackbar, users.length]);

  return (
    <Container>
      <Typography variant="h3" gutterBottom={true}>
        Users
      </Typography>
      {users.length ? (
        <Table>
          <TableBody>
            {users.map((user) => {
              return (
                <UserRow key={user.uid}>
                  <TableCell>{user.displayName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.customClaims && user.customClaims.admin}
                          onChange={onChangeAdmin(user)}
                        ></Checkbox>
                      }
                      label="Admin"
                    ></FormControlLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.customClaims && user.customClaims.read}
                          onChange={onChangeAuthorize(user)}
                        ></Checkbox>
                      }
                      label="Authorize"
                    ></FormControlLabel>
                    <IconButton onClick={onOpenSelect(user)}>
                      <SyncIcon />
                    </IconButton>
                    <IconButton onClick={onDelete(user)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </UserRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <LinearProgress />
      )}
      <PersonSelect
        open={isUserSelectOpen}
        onClose={onCloseSelect}
        onSelect={onSync}
        modal={true}
      ></PersonSelect>
    </Container>
  );
}
