import { Alert } from "@material-ui/lab";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStateContext } from "../contexts/state";

export default function Unauthorized() {
  const [{ user }] = useStateContext();
  const history = useHistory();

  useEffect(() => {
    if (!user.uid) {
      history.push("/login");
    }
  }, [user, history]);

  return (
    <Alert severity="info" variant="filled">
      You are not authorized to view this app. Please contact Bethany for
      access.
    </Alert>
  );
}
