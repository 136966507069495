import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import PersonSelect from "../components/personSelect";
import PersonEdit from "../components/personEdit";

import { useStateContext } from "../contexts/state";

export default function FamilyMembers() {
  const [defaultSelected, setDefaultSelected] = useState(null);
  const [selected, setSelected] = useState();
  const [{ family, user }] = useStateContext();

  useEffect(() => {
    const match = Object.keys(family).filter(
      (id) => family[id].uid === user.uid
    );

    if (match.length && !selected && !defaultSelected) {
      const id = match[0];

      setDefaultSelected(id);
      setSelected(id);
    }
  }, [family, user, selected, defaultSelected]);

  return (
    <Container>
      <Typography gutterBottom={true} variant="h3">Family Members</Typography>
      <PersonSelect
        label="Select..."
        selected={selected}
        onSelect={setSelected}
      ></PersonSelect>
      <PersonEdit person={family[selected]} onChange={setSelected}></PersonEdit>
    </Container>
  );
}
