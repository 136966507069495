import {
  FAMILY_ADD,
  FAMILY_DELETE,
  FAMILY_FETCH,
  FAMILY_UPDATE,
} from "../actions/family";

export const familyReducer = (state, action) => {
  const payload = action.payload;

  switch (action.type) {
    case FAMILY_FETCH:
      return { ...action.payload };
    case FAMILY_ADD:
    case FAMILY_UPDATE:
      const id = payload.id;
      return { ...state, [id]: payload };
    case FAMILY_DELETE:
      const { [payload]: val, ...rest } = state;
      return { ...rest };
    default:
      return { ...state };
  }
};

export const familyInitialState = {loading: true};
