import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import isBefore from "date-fns/isBefore";
import isFuture from "date-fns/isFuture";
import isPast from "date-fns/isPast";
import isToday from "date-fns/isToday";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { Alert } from "@material-ui/lab";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import Event from "../components/event";
import theme from "../styles/theme";

import { useStateContext } from "../contexts/state";

const now = new Date();
const thisYear = now.getFullYear();

export default function EventList() {
  const [{ family }] = useStateContext();
  const [events, setEvents] = useState([]);
  const [range, setRange] = useState("60d");
  const [search, setSearch] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [type, setType] = useState("all");
  const [loading, setLoading] = useState(true);

  const onRangeChange = (event) => {
    setRange(event.target.value);
  };

  const onTypeChange = (event) => {
    setType(event.target.value);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const isType = (event) => {
      if ("all" === type) {
        return true;
      }
      return event.type === type;
    };

    const matchesSearch = (event) => {
      if (!search) {
        return true;
      }

      return event.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
    };

    const matchesFilters = (event) => {
      return isType(event) && matchesSearch(event);
    };

    const filterEvents = (event) => {
      const date = new Date(event.date);

      // Adjust year, since it doesn't matter for comparison
      date.setFullYear(thisYear);

      const isEventToday = isToday(date);

      if (!isEventToday && isBefore(date, now)) {
        // set to next year if event is past
        date.setFullYear(thisYear + 1);
      }

      if ("all" === range || isEventToday) {
        return matchesFilters(event);
      }

      if (
        "30d" === range &&
        isFuture(date) &&
        differenceInCalendarDays(date, now) < 31
      ) {
        return matchesFilters(event);
      }

      if (
        "60d" === range &&
        isFuture(date) &&
        differenceInCalendarDays(date, now) < 61
      ) {
        return matchesFilters(event);
      }

      return false;
    };

    const filteredEvents = allEvents
      .filter(filterEvents)
      .map((event, index) => {
        let color;
        const n = (index % 5) + 1;

        switch (n) {
          case 1:
            color = theme.colors.navy;
            break;
          case 2:
            color = theme.colors.blue;
            break;
          case 3:
            color = theme.colors.teal;
            break;
          case 4:
            color = theme.colors.red;
            break;
          default:
            color = theme.colors.yellow;
            break;
        }

        return (
          <CSSTransition key={event.id} timeout={500} classNames="fade">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Event event={event} color={color}></Event>
            </Grid>
          </CSSTransition>
        );
      });

    setEvents(filteredEvents);
  }, [allEvents, range, type, search]);

  useEffect(() => {
    if (loading && events.length) {
      setLoading(false);
    }
  }, [allEvents, events, loading]);

  useEffect(() => {
    const getRecurringEventYear = (date) => {
      const thisYear = new Date().getFullYear();
      const eventDate = new Date(date);
      let eventYear = thisYear;

      eventDate.setFullYear(thisYear);

      if (!isToday(eventDate) && isPast(eventDate)) {
        eventYear++;
      }

      return eventYear;
    };

    const getSortDate = (date, year) => {
      const eventDate = new Date(date);
      let month = eventDate.getMonth();
      let day = eventDate.getDate();

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      return parseInt(`${year}${month}${day}`);
    };

    const getSortedEvents = (data) => {
      const events = [];

      Object.keys(data).forEach((id) => {
        const person = data[id];
        const birthdayYear = getRecurringEventYear(person.birthday);

        events.push({
          id: id,
          type: "birthday",
          date: person.birthday,
          nextRecurringYear: birthdayYear,
          sort: getSortDate(person.birthday, birthdayYear),
          ...person,
        });

        if (person.spouse && !person.inLaw && person.anniversary) {
          const spouse = data[person.spouse];
          const anniversaryYear = getRecurringEventYear(person.anniversary);

          if (spouse) {
            events.push({
              id: events.length,
              name: `${person.name} & ${spouse.name}`,
              sort: getSortDate(person.anniversary, anniversaryYear),
              date: person.anniversary,
              nextRecurringYear: anniversaryYear,
              type: "anniversary",
            });
          }
        }
      });

      return events.sort((a, b) => a.sort - b.sort);
    };

    if (family.loading) {
      return;
    }

    const sortedEvents = getSortedEvents(family);
    
    setAllEvents(sortedEvents);
  }, [family]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          justifyContent="center"
          margin="normal"
        >
          <Grid item xs={6} md={3}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Range</InputLabel>
              <Select value={range} onChange={onRangeChange} label="Range">
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="30d">Next 30 days</MenuItem>
                <MenuItem value="60d">Next 60 days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Type</InputLabel>
              <Select value={type} onChange={onTypeChange} label="Type">
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="anniversary">Anniversary</MenuItem>
                <MenuItem value="birthday">Birthday</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth={true}
              variant="outlined"
              placeholder="Search..."
              onChange={onSearchChange}
              type="search"
            />
          </Grid>
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : events.length ? (
          <Grid
            item
            container
            component={TransitionGroup}
            xs={12}
            spacing={2}
            justifyContent="center"
          >
            {events}
          </Grid>
        ) : (
          <Container>
            <Alert severity="info">
              Sorry, there are no results to display.
            </Alert>
          </Container>
        )}
      </Grid>
    </Container>
  );
}
