import { createTheme } from '@material-ui/core/styles';

export const theme = {
  colors: {
    yellow: "#edae49",
    navy: "#003d5b",
    blue: "#30638e",
    teal: "#00798c",
    red: "#d1495b",
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#003d5b",
    },
    secondary: {
      main: "#edae49",
    },
    cards: {
      yellow: theme.yellow,
      navy: theme.navy,
      blue: theme.blue,
      teal: theme.teal,
      red: theme.red,
    },
  },
  typography: {
    htmlFontSize: 10,
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
});

export default theme;
