import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/styles";

import "fontsource-roboto/300.css";
import "fontsource-roboto/400.css";
import "fontsource-roboto/500.css";
import "fontsource-roboto/700.css";

import "./styles/base.css";
import "./styles/animations.css";

import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { muiTheme } from "./styles/theme";

import { FirebaseProvider } from "./contexts/firebase";
import { StateProvider } from "./contexts/state";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FirebaseProvider>
          <StateProvider>
            <App />
          </StateProvider>
        </FirebaseProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
