import { Container, Paper, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { muiTheme } from "../styles/theme";

const Wrapper = withStyles({
  root: {
    padding: muiTheme.spacing(3),
  },
})(Container);

export default function Privacy() {
  return (
    <Paper>
      <Wrapper>
        <Typography variant="h2">Privacy Policy</Typography>
        <Typography variant="subtitle2" paragraph={true}>
          Last updated: October 9, 2020
        </Typography>
        <Typography variant="body1" paragraph={true}>
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information we receive from users of the
          Site.
        </Typography>
        <Typography variant="body1" paragraph={true}>
          We use your Personal Information only for providing and improving the
          Site. By using the Site, you agree to the collection and use of
          information in accordance with this policy.
        </Typography>
        <Typography variant="h5">Information Collection And Use</Typography>
        <Typography variant="body1" paragraph={true}>
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to your name and email. ("Personal Information").
        </Typography>
        <Typography variant="h5">Security</Typography>
        <Typography variant="body1" paragraph={true}>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage, is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </Typography>
        <Typography variant="h5">Changes To This Privacy Policy</Typography>
        <Typography variant="body1" paragraph={true}>
          This Privacy Policy is effective as of October 9th, 2020 and will
          remain in effect except with respect to any changes in its provisions
          in the future, which will be in effect immediately after being posted
          on this page.
        </Typography>
        <Typography variant="body1" paragraph={true}>
          We reserve the right to update or change our Privacy Policy at any
          time and you should check this Privacy Policy periodically. Your
          continued use of the Service after we post any modifications to the
          Privacy Policy on this page will constitute your acknowledgment of the
          modifications and your consent to abide and be bound by the modified
          Privacy Policy.
        </Typography>
        <Typography variant="body1">
          If we make any material changes to this Privacy Policy, we will notify
          you either through the email address you have provided us, or by
          placing a prominent notice on our website.
        </Typography>
      </Wrapper>
    </Paper>
  );
}
