import { Link as RouterLink } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EventIcon from "@material-ui/icons/Event";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";

import FirebaseContext from "../contexts/firebase";
import { useStateContext } from "../contexts/state";
import { USER_RESET } from "../actions/user";

const Container = withStyles({
  root: {
    backgroundColor: "#333333",
  },
})(AppBar);

const SideNav = withStyles({
  paper: {
    minWidth: "22rem",
  },
})(Drawer);

const DrawerLinkList = withStyles({
  root: { marginTop: "5rem", minWidth: "20rem" },
})(List);

const Name = withStyles({
  root: {
    flexGrow: 1,
  },
})(Typography);

export default function Header(props) {
  const [{ user }, dispatch] = useStateContext();
  const { signOut } = useContext(FirebaseContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [routes, setRoutes] = useState([]);

  const toggleDrawer = (isOpen) => (event) => {
    setDrawerOpen(isOpen);
  };

  const onSignOut = () => {
    dispatch({ type: USER_RESET });
    signOut();
  };

  useEffect(() => {
    const links = [
      {
        label: "Calendar",
        route: "/",
        icon: <EventIcon />,
      },
      {
        label: "Profile",
        route: "/profile",
        icon: <AccountBoxIcon />,
      },
    ];

    if (user && user.isAdmin) {
      links.push(
        {
          label: "Family Info",
          route: "/familyInfo",
          icon: <PeopleIcon />,
        },
        {
          label: "Users",
          route: "/users",
          icon: <GroupAddIcon />,
        }
      );
    }

    setRoutes([...links]);
  }, [user]);

  return (
    <div>
      <Container position="static">
        <Toolbar>
          <IconButton onClick={toggleDrawer(true)} color="inherit">
            <MenuIcon></MenuIcon>
          </IconButton>
          <Name variant="h6">Hi {user.displayName}</Name>
          <Button color="inherit" onClick={() => onSignOut()}>
            Logout
          </Button>
        </Toolbar>
      </Container>
      {user && (
        <SideNav open={drawerOpen} onClose={toggleDrawer(false)}>
          <DrawerLinkList>
            {routes.map((route, index) => (
              <ListItem
                button
                key={index}
                component={RouterLink}
                to={route.route}
                onClick={toggleDrawer(false)}
              >
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText>{route.label}</ListItemText>
              </ListItem>
            ))}
          </DrawerLinkList>
        </SideNav>
      )}
    </div>
  );
}
