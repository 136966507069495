import {
  USER_REMOVE_PHOTO,
  USER_RESET,
  USER_SET,
  USER_UPDATE,
  USER_UPLOAD_PHOTO,
} from "../actions/user";

export const userReducer = (state, action) => {
  switch (action.type) {
    case USER_SET:
      const firebaseUser = action.payload.user;
      const idTokenResult = action.payload.token;
      let photoURL = firebaseUser.photoURL;

      if (
        photoURL &&
        photoURL.includes("facebook.com") &&
        !photoURL.includes("?type=")
      ) {
        photoURL += "?type=large";
      }

      return {
        uid: firebaseUser.uid,
        displayName: firebaseUser.displayName,
        email: firebaseUser.email,
        phoneNumber: firebaseUser.phoneNumber,
        photoURL: photoURL,
        isAuthorized: idTokenResult.claims.read,
        isAdmin: idTokenResult.claims.admin,
      };
    case USER_UPDATE:
      return { ...state, ...action.payload };
    case USER_RESET:
      return { loading: false, isAuthorized: false, isAdmin: false };
    case USER_REMOVE_PHOTO:
      return { ...state, photoURL: "" };
    case USER_UPLOAD_PHOTO:
      return { ...state, photoURL: action.payload };
    default:
      return state;
  }
};

export const userInitialState = {
  loading: true,
  isAuthorized: false,
  isAdmin: false,
};
