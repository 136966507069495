import { Field, withFormik } from "formik";
import React, { createRef, useState } from "react";
import { useSnackbar } from "notistack";

import {
  Avatar,
  Container,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  withStyles,
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

import ProgressButton from "./progressButton";

import { useFirebase } from "../contexts/firebase";
import { useStateContext, withUser } from "../contexts/state";
import {
  USER_UPDATE,
  USER_REMOVE_PHOTO,
  USER_UPLOAD_PHOTO,
} from "../actions/user";

const Photo = withStyles({
  root: {
    width: "100%",
    height: "auto",
  },
})(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiButton-root": {
      margin: theme.spacing(1),
    },
  },
}));

function Editor(props) {
  const { dirty, isValid, values, errors, setFieldValue } = props;
  const { auth, upload, updateAuthUser } = useFirebase();
  const [{ user }, dispatch] = useStateContext();
  const classes = useStyles();
  const fileRef = createRef();
  const [uploadEl, setUploadEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);

  const onFileChange = async (event) => {
    const url = URL.createObjectURL(event.target.files[0]);

    setFieldValue("photoURL", url);
    setUploading(true);

    try {
      const downloadURL = await upload(event.target.files[0]);

      await auth().currentUser.updateProfile({ photoURL: downloadURL });
      dispatch({ type: USER_UPLOAD_PHOTO, payload: downloadURL });
      enqueueSnackbar("Image successfully uploaded", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("File could not be uploaded", { variant: "error" });
    } finally {
      setUploading(false);
    }
  };

  const removePhoto = async (event) => {
    setFieldValue("photoURL", "");
    setUploadEl(null);
    setUploading(true);

    try {
      await auth().currentUser.updateProfile({ photoURL: "" });
      dispatch({ type: USER_REMOVE_PHOTO });
      enqueueSnackbar("Image successfully removed", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Image could not be updated", { variant: "error" });
    } finally {
      setUploading(false);
    }
  };

  const onUploadClick = (event) => {
    fileRef.current.click();
    setUploadEl(null);
  };

  const onUploadMenuClick = (event) => {
    setUploadEl(event.currentTarget);
  };

  const onCloseUploadMenu = () => {
    setUploadEl(null);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSaving(true);

    const updateUser = async () => {
      const updates = { ...values };

      try {
        await updateAuthUser(user.uid, updates);
        dispatch({ type: USER_UPDATE, payload: updates });
        enqueueSnackbar("User successfully updated", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Error updating user", { variant: "error" });
      } finally {
        setSaving(false);
      }
    };

    updateUser();
  };

  return (
    <Container disableGutters={true} className={classes.root}>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Photo variant="square" src={values.photoURL} />
            <input
              type="file"
              ref={fileRef}
              variant="outlined"
              onChange={onFileChange}
              accept="image/*"
              hidden
            />
            <ProgressButton
              onClick={onUploadMenuClick}
              startIcon={<CameraAltIcon />}
              label="Edit Photo"
              loading={uploading}
            />
            <Menu
              anchorEl={uploadEl}
              keepMounted
              open={Boolean(uploadEl)}
              onClose={onCloseUploadMenu}
            >
              <MenuItem onClick={onUploadClick}>Upload Photo</MenuItem>
              <MenuItem onClick={removePhoto}>Remove Photo</MenuItem>
            </Menu>
          </Grid>
          <Grid item sm={8}>
            <Field
              as={TextField}
              name="displayName"
              label="Display Name"
              variant="outlined"
              fullWidth={true}
              error={errors.displayName}
              required
            ></Field>

            <Field
              as={TextField}
              name="email"
              label="Email"
              variant="outlined"
              fullWidth={true}
              error={errors.email}
              type="email"
              required
            ></Field>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              loading={saving}
              disabled={!dirty || !isValid}
              label="Save"
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

const UserEdit = withUser(
  withFormik({
    validate: (values) => {
      const errors = {};

      if (values.displayName === "") {
        errors.displayName = "Display Name is required";
      }

      if (values.email === "") {
        errors.email = "Email is required";
      }

      return errors;
    },
    mapPropsToValues: (props) => {
      const user = props.user || {};

      return {
        displayName: user.displayName || "",
        email: user.email || "",
        photoURL: user.photoURL,
      };
    },
  })(Editor)
);

export default UserEdit;
