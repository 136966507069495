import React from "react";
import { Container, Typography } from "@material-ui/core";

import UserEdit from "../components/userEdit";

export default function Profile() {
  return (
    <Container>
      <Typography variant="h3" gutterBottom={true}>Account</Typography>
      <UserEdit />
    </Container>
  );
}
