import { Button, CircularProgress, withStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React from "react";

const ProgressIcon = withStyles({
  root: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
})(CircularProgress);

export default function ProgressButton(props) {
  const { disabled, label, loading, ...buttonProps } = props;

  return (
    <Button disabled={disabled || loading} {...buttonProps}>
      {label}
      {loading && <ProgressIcon size="2.5rem" />}
    </Button>
  );
}
