import { Container, withStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";

import { FAMILY_FETCH } from "../actions/family";
import { USER_RESET, USER_SET } from "../actions/user";

import { useFirebase } from "../contexts/firebase";
import { useStateContext } from "../contexts/state";

const Content = withStyles({
  root: {
    padding: "2rem",
    maxWidth: "98rem",
    margin: "0 auto",
  },
})(Container);

export default function Login() {
  const { auth, getFamilyMembers } = useFirebase();
  const history = useHistory();
  const location = useLocation();
  const [{ family, user }, dispatch] = useStateContext();
  const { enqueueSnackbar } = useSnackbar();
  const { from } = location.state || { from: { pathname: "/" } };

  const authUIConfig = {
    signInOptions: [
      auth.EmailAuthProvider.PROVIDER_ID,
      auth.GoogleAuthProvider.PROVIDER_ID
    ],
    signInFlow: "redirect",
    callbacks: {
      signInSuccessWithAuthResult: () => {},
    },
  };

  useEffect(() => {
    const observer = auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        dispatch({ type: USER_RESET });
        return;
      }

      authUser.getIdTokenResult().then((idTokenResult) => {
        dispatch({
          type: USER_SET,
          payload: {
            user: authUser,
            token: idTokenResult,
          },
        });
      });
    });

    return () => observer();
  }, [auth, dispatch]);

  // Init family
  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = await getFamilyMembers();
        const data = {};

        if (!query) return;

        query.forEach((doc) => {
          data[doc.id] = { id: doc.id, ...doc.data() };
        });
        dispatch({ type: FAMILY_FETCH, payload: data });
      } catch (error) {
        enqueueSnackbar("Error loading family data", { variant: "error" });
      }
    };
    if (!user.isAuthorized || !family.loading) {
      return;
    }
    fetchData();
  }, [getFamilyMembers, enqueueSnackbar, family, user, dispatch]);

  useEffect(() => {
    if (!history || !user.uid) return;

    if (user.uid) {
      history.replace(from);
    }
  }, [user, history, from]);

  return (
    <Content>
      <StyledFirebaseAuth uiConfig={authUIConfig} firebaseAuth={auth()} />
    </Content>
  );
}
