import { Button, Dialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import React from "react";

export default function ConfirmationDialog(props) {
  const {
    children,
    cancelText,
    confirmText,
    onClose,
    onConfirm,
    open,
    title,
  } = props;

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const onConfirmClick = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <MuiDialogTitle>{title || "Confirm"}</MuiDialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
      <MuiDialogActions>
        <Button onClick={onConfirmClick}>{confirmText || "Yes"}</Button>
        <Button onClick={onCancel}>{cancelText || "No"}</Button>
      </MuiDialogActions>
    </Dialog>
  );
}
