import React from "react";
import styled from "styled-components";

import { getMonthName } from "../utils/dates";
import theme from "../styles/theme";

import { ReactComponent as HeartSVG } from "../images/heart.svg";

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 28rem;
  border-radius: 5px;
  perspective: 100rem;
  color: #333;
  font-weight: 600;
  text-align: center;

  /*:hover {
    .card-inner {
      transform: rotateY(180deg);
    }
  }*/

  .card-back,
  &.event-birthday .date,
  .name {
    background-color: ${(props) => props.color};
  }

  .date:before {
    color: ${(props) => props.color};
  }
`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

const CardTop = styled.div`
  padding: 3rem;
`;

const cardFace = `
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  background-color: white;
`;

const CardFront = styled.div`
  ${cardFace}
`;

const CardBack = styled.div`
  ${cardFace}
  transform: rotateY( 180deg );
  color: #fff;
  font-size: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Year = styled.div`
  font-size: 2rem;
`;

const Month = styled.div`
  font-size: 3rem;
  text-transform: uppercase;
`;

const Day = styled.div`
  font-size: 10rem;
`;

const Count = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3rem;
  backface-visibility: hidden;

  .event-anniversary & {
    width: 7rem;
    color: white;
    transform: scale(1);
  }

  &:before {
    content: "▲";
    font-size: 2rem;
    color: rgb(209, 73, 91);
    display: block;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: -1.2rem;
  }

  &:after {
    display: inline-block;
    top: 6.7rem;
    position: absolute;
    height: 4rem;
    width: 1px;
    margin: 0 auto;
    content: "";
    background: rgba(0,0,0,0.2);
  }
`;

const Name = styled.div`
  padding: 1.5rem 2rem;
  color: white;
`;

const Heart = styled(HeartSVG)`
  fill: ${theme.colors.red};
  width: 7rem;
  position: absolute;
  z-index: -1;
`;

export default function Event(props) {
  const event = props.event;
  const date = new Date(event.date);
  const eventYear = date.getFullYear();
  const count = props.event.nextRecurringYear - eventYear;
  const month = getMonthName(date.getMonth()).substring(0, 3);
  const day = date.getDate();

  return (
    <Card className={"event-" + event.type} color={props.color}>
      <CardInner className="card-inner">
        <CardFront>
          <CardTop>
            <Count className={event.type === "anniversary" ? "heart" : "date"}>
              {count}
              {event.type === "anniversary" ? <Heart /> : null}
            </Count>
            <Month>{month}</Month>
            <Day>{day}</Day>
            <Year>{event.nextRecurringYear}</Year>
          </CardTop>
          <Name className="name">{event.name}</Name>
        </CardFront>
        <CardBack className="card-back">{count}</CardBack>
      </CardInner>
    </Card>
  );
}
