import {
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, withFormik } from "formik";
import { useSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useFirebase } from "../contexts/firebase";
import { useStateContext } from "../contexts/state";

import ConfirmationDialog from "./confirmDialog";
import PersonSelect from "./personSelect";
import ProgressButton from "./progressButton";

import { FAMILY_ADD, FAMILY_DELETE, FAMILY_UPDATE } from "../actions/family";

const Form = styled.form`
  width: 100%;
`;

function Editor(props) {
  const { errors, values, setFieldValue, dirty, isValid, resetForm } = props;
  const [datePickers, setDatePickers] = useState(["birthday"]);
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [childrenFilter, setChildrenFilter] = useState(() => () => true);
  const [{ family, user }, dispatch] = useStateContext();
  const [disabled, setDisabled] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const {
    addFamilyMember,
    updateFamilyMember,
    deleteFamilyMember,
  } = useFirebase();

  const onDateChange = (key) => (value) => {
    const date = new Date(value);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    setFieldValue(key, `${month}/${day}/${year}`);
  };

  const onSelectSpouse = (selected) => {
    if (!selected) {
      setFieldValue("spouse", "");
      setFieldValue("anniversary", "");
      return;
    }

    setFieldValue("spouse", selected);
  };

  const onSelectChildren = (selected) => {
    if (!selected) {
      setFieldValue("children", []);
      return;
    }

    // Options selected via autocomplete return objects, but original selections use id
    const children = selected.map((child) => {
      return child.id || child;
    });

    setFieldValue("children", children);
  };

  const onSubmit = async (event) => {
    let id;

    event.preventDefault();
    setSaving(true);

    try {
      if (props.person) {
        const payload = { ...values, id: props.person.id };
        id = payload.id;
        await updateFamilyMember(payload);
        dispatch({
          type: FAMILY_UPDATE,
          payload: payload,
        });
        enqueueSnackbar("Changes have been updated.", { variant: "success" });
      } else {
        const doc = await addFamilyMember(values);
        id = doc.id;
        dispatch({
          type: FAMILY_ADD,
          payload: { ...values, id: id },
        });
        enqueueSnackbar("Family member successfully added", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar("There was an error saving your changes", {
        variant: "error",
      });
    } finally {
      setSaving(false);
      if (props.onChange) {
        props.onChange({ id: id, ...values });
      }
    }
  };

  const onDelete = async () => {
    const id = props.person.id;

    setDeleting(true);

    try {
      await deleteFamilyMember(id);
      dispatch({
        type: FAMILY_DELETE,
        payload: id,
      });
      enqueueSnackbar("Deletion successful", { variant: "success" });
      resetForm();

      if (props.onChange) {
        props.onChange();
      }
    } catch (error) {
      enqueueSnackbar("Person could not be deleted", { variant: "error" });
    } finally {
      setDeleting(false);
      toggleDeleteDialog(false)();
    }
  };

  const inLawFilter = (person) => {
    return person.inLaw;
  };

  const toggleDeleteDialog = (open) => () => {
    setOpenDelete(open);
  };

  useEffect(() => {
    if (values.spouse) {
      setDatePickers(["birthday", "anniversary"]);
    } else {
      setDatePickers(["birthday"]);
    }
  }, [values.spouse]);

  useEffect(() => {
    if (!values.generation) {
      setChildrenFilter(() => () => true);
    }
    setChildrenFilter(() => (person) => {
      if (!person || values.generation === "") {
        return true;
      }
      return values.generation - person.generation === -1;
    });
  }, [values.generation]);

  useEffect(() => {
    const selected = props.person;
    const uid = selected ? selected.uid : null;

    if (user && (user.isAdmin || user.uid === uid)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user, props.person, family]);

  return (
    <Form onSubmit={onSubmit}>
      <Grid spacing={2} container>
        {["name", "email"].map((key) => {
          return (
            <Grid key={key} item xs={12} md={6}>
              <Field
                as={TextField}
                name={key}
                variant="outlined"
                label={key}
                margin="normal"
                error={!!errors[key]}
                helperText={errors[key]}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
              />
            </Grid>
          );
        })}
        {["generation"].map((key) => {
          return (
            <Grid key={key} item xs={12} md={6}>
              <FormControl
                error={errors[key]}
                variant="outlined"
                fullWidth={true}
                disabled={disabled}
              >
                <InputLabel>{key}</InputLabel>
                <Field name={key} as={Select} label={key}>
                  <MenuItem value={1}>1st</MenuItem>
                  <MenuItem value={2}>2nd</MenuItem>
                  <MenuItem value={3}>3rd</MenuItem>
                  <MenuItem value={4}>4th</MenuItem>
                </Field>
              </FormControl>
            </Grid>
          );
        })}
        <Grid key="spouse" item xs={12} md={6}>
          <PersonSelect
            label="spouse"
            selected={values.spouse}
            onSelect={onSelectSpouse}
            filter={inLawFilter}
            disabled={disabled}
          ></PersonSelect>
        </Grid>
        <Grid key="children" item xs={12}>
          <PersonSelect
            label="Children"
            multiple={true}
            selected={values.children}
            onSelect={onSelectChildren}
            filter={childrenFilter}
            disabled={disabled}
          ></PersonSelect>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {datePickers.map((key) => {
            return (
              <Grid key={key} item xs={12} md={6}>
                <DatePicker
                  id={key}
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  label={key}
                  margin="normal"
                  error={!!errors[key]}
                  value={values[key]}
                  onChange={onDateChange(key)}
                  helperText={errors[key]}
                  fullWidth={true}
                  disabled={disabled}
                />
              </Grid>
            );
          })}
        </MuiPickersUtilsProvider>

        <Grid key="inLaw" item xs={12}>
          <FormControlLabel
            control={
              <Field as={Checkbox} name="inLaw" checked={values.inLaw}></Field>
            }
            label="In-law"
            disabled={disabled}
          ></FormControlLabel>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup variant="contained" color="primary">
            <ProgressButton
              type="submit"
              label="Save"
              loading={saving}
              disabled={!dirty || !isValid}
            />
            <ProgressButton
              type="button"
              label="Delete"
              loading={deleting}
              disabled={!!!props.person}
              onClick={toggleDeleteDialog(true)}
            />
          </ButtonGroup>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={openDelete}
        onConfirm={onDelete}
        onClose={toggleDeleteDialog(false)}
      >
        Are you sure you want to delete?
      </ConfirmationDialog>
    </Form>
  );
}

const PersonEdit = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const person = props.person;

    if (person) {
      const {
        name,
        email,
        birthday,
        generation,
        anniversary,
        inLaw,
        spouse,
        children,
      } = person;

      return {
        name: name || "",
        email: email || "",
        birthday: birthday || "",
        generation: generation || "",
        anniversary: anniversary || "",
        spouse: spouse || "",
        inLaw: inLaw === true,
        children: children || [],
      };
    }
    return {
      name: "",
      email: "",
      birthday: "",
      generation: "",
      anniversary: "",
      spouse: "",
      children: [],
      inLaw: false,
    };
  },
  validate: (values) => {
    const errors = {};

    if ("" === values.name) {
      errors.name = "Name is required";
    }

    if ("" === values.birthday) {
      errors.birthday = "Birthday is required";
    }

    if ("" === values.generation) {
      errors.generation = "Generation is required";
    }

    if ("" !== values.spouse && "" === values.anniversary) {
      errors.anniversary = "Anniversary is required";
    }

    return errors;
  },
})(Editor);

export default PersonEdit;
