import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Container, withStyles } from "@material-ui/core";
import React from "react";

import EventList from "./pages/eventList";
import FamilyMembers from "./pages/familyMembers";
import Header from "./components/header";
import Login from "./pages/login";
import Privacy from "./pages/privacy";
import Profile from "./pages/profile";
import Unauthorized from "./pages/unauthorized";
import Users from "./pages/users";

import AdminRoute from "./routes/admin";
import AuthorizedRoute from "./routes/authorized";
import UnauthorizedRoute from "./routes/unauthorized";

import { useStateContext } from "./contexts/state";

const Content = withStyles({
  root: {
    padding: "2rem",
  },
})(Container);

function App() {
  const [{user}] = useStateContext();

  return (
    <>
      <Router>
        {user.uid && <Header></Header>}
        <Content fluid="true">
            <Switch>
              <AdminRoute path="/familyInfo">
                <FamilyMembers />
              </AdminRoute>
              <AdminRoute path="/users">
                <Users />
              </AdminRoute>
              <Route path="/login" component={Login} />
              <AuthorizedRoute path="/profile">
                <Profile />
              </AuthorizedRoute>
              <Route path="/privacy" component={Privacy} />
              <UnauthorizedRoute path="/unauthorized">
                <Unauthorized />
              </UnauthorizedRoute>
              <AuthorizedRoute exact path="/">
                <EventList />
              </AuthorizedRoute>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
        </Content>
      </Router>
    </>
  );
}

export default App;
