import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Dialog, TextField } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import { useStateContext } from "../contexts/state";

export default function PersonSelect(props) {
  const defaultSelected = props.multiple ? [] : null;
  const [{family}] = useStateContext();
  const [selected, setSelected] = useState(defaultSelected);
  const { onClose, onSelect, open, modal, ...selectProps } = props;
  const [options, setOptions] = useState([]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const person = family[selected];

    onSelect(person);
  };

  const onChange = (event, option) => {
    let value = option;

    if (option && option.id) {
      value = option.id;
    }

    setSelected(value);

    if (!modal && onSelect) {
      onSelect(value);
    }
  };

  const getOptionLabel = (option) => {
    const person = family[option];

    if (option && option.id) {
      return option.name;
    } else if (person) {
      return person.name;
    }

    return "";
  };

  const getOptionSelected = (option, value) => {
    if (value && value.id) {
      return option.id === value.id;
    } else if (option && option.id) {
      return option.id === value;
    }
    return option === value;
  };

  useEffect(() => {
    const filterFn = () => true;
    const opts = Object.keys(family)
      .map((id) => family[id])
      .filter(props.filter || filterFn);

    setOptions([...opts]);
  }, [family, props.filter]);

  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected);
    } else if (props.multiple) {
      setSelected([]);
    } else {
      setSelected(null);
    }
  }, [props.selected, props.multiple]);

  const autocomplete = (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      style={{ minWidth: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label || "Search"}
          variant="outlined"
        />
      )}
      value={selected}
      onChange={onChange}
      {...selectProps}
    />
  );

  if (modal) {
    return (
      <Dialog open={open} onClose={onCancel}>
        <MuiDialogTitle>Select a User</MuiDialogTitle>
        <MuiDialogContent>{autocomplete}</MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={onSave}>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </MuiDialogActions>
      </Dialog>
    );
  } else {
    return autocomplete;
  }
}
