import { familyReducer, familyInitialState } from "./family";
import { userInitialState, userReducer } from "./user";
import { usersReducer, usersInitialState } from "./users";

export const rootReducer = ({ family, users, user }, action) => ({
  family: familyReducer(family, action),
  users: usersReducer(users, action),
  user: userReducer(user, action)
});

export const initialState = {
  family: familyInitialState,
  users: usersInitialState,
  user: userInitialState,
};
