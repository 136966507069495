import app from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

app.initializeApp(config);

const auth = app.auth;
const db = app.firestore();
const functions = app.functions();
const storageRef = app.storage().ref();

if ("development" === process.env.NODE_ENV) {
  functions.useFunctionsEmulator("http://localhost:5001");

  db.settings({
    host: "localhost:8080",
    ssl: false
  });
}

/*********************
 * Auth User Functions
 *********************/

const callHttpsFunction = (name, params) => {
  const fn = functions.httpsCallable(name);

  return fn(params);
};

const addAdmin = (uid) => {
  const fn = functions.httpsCallable("addAdmin");

  return fn({ uid: uid });
};

const authorizeUser = (uid) => {
  const fn = functions.httpsCallable("authorizeUser");

  return fn({ uid: uid });
};

const updateAuthUser = (uid, user) => {
  const fn = functions.httpsCallable("updateUser");

  return fn({ uid: uid, user: user });
};

const deleteAuthUser = (uid) => {
  const fn = functions.httpsCallable("deleteUser");

  return fn({ uid: uid });
};

const getAuthUsers = () => {
  const fn = functions.httpsCallable("getUsers");

  return fn();
};

const removeAdmin = (uid, canRead) => {
  const fn = functions.httpsCallable("removeAdmin");

  return fn({ uid: uid, canRead: canRead });
};

const signOut = () => {
  auth().signOut();
};

const unauthorizeUser = (uid) => {
  const fn = functions.httpsCallable("unauthorizeUser");

  return fn({ uid: uid });
};

/*********************
 * Storage Functions
 *********************/

const upload = (file) => {
  const uploadTask = storageRef.child("images/" + file.name).put(file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      app.storage.TaskEvent.STATE_CHANGED,
      () => {},
      (error) => {
        reject(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};

/*********************
 * Firestore Functions
 *********************/

const familyCollection = db.collection("people");

const getFamilyMembers = () => {
  return familyCollection.get();
};

const addFamilyMember = (value) => {
  return familyCollection.add(value);
};

const updateFamilyMember = ({ id, ...value }) => {
  return familyCollection.doc(id).update(value);
};

const deleteFamilyMember = (id) => {
  return familyCollection.doc(id).delete();
};

export default {
  auth,
  addAdmin,
  removeAdmin,
  getAuthUsers,
  updateAuthUser,
  deleteAuthUser,
  authorizeUser,
  unauthorizeUser,
  signOut,
  upload,
  getFamilyMembers,
  addFamilyMember,
  updateFamilyMember,
  deleteFamilyMember,
  callHttpsFunction,
};
